// src/address.ts
import { getAddress } from "@ethersproject/address";
function shortenAddress(address, characters = 4) {
  try {
    const parsed = getAddress(address);
    return `${parsed.substring(0, characters + 2)}...${parsed.substring(42 - characters)}`;
  } catch (error) {
    throw `Invalid 'address' parameter '${address}'.`;
  }
}

// src/number.ts
import numeral from "numeral";
var formatNumber = (value) => {
  return numeral(value).format("(0.00a)");
};

// src/percent.ts
import numeral2 from "numeral";
var formatPercent = (value) => {
  return numeral2(value).format("(0.00%)");
};

// src/price.ts
import numeral3 from "numeral";
var formatUSD = (value, inputString = "$0.00a") => {
  if (typeof value === "string")
    value = Number(value);
  if (value < 1e-6)
    return "$0.00";
  if (value < 1e-4)
    return numeral3(value).format("$0.000000a");
  if (value < 1e-3)
    return numeral3(value).format("$0.0000a");
  if (value < 0.01)
    return numeral3(value).format("$0.000a");
  return numeral3(value).format(inputString);
};
export {
  formatNumber,
  formatPercent,
  formatUSD,
  shortenAddress
};
