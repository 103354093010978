import * as React from 'react'

export const BobaBNBCircle = (props: React.ComponentProps<'svg'>) => (
  <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="128" height="128" rx="64" fill="url(#paint0_linear_706_842)" />
    <g clipPath="url(#clip0_706_842)">
      <path
        d="M89.7166 38.8215C89.7266 38.8415 89.7466 38.8615 89.7666 38.8715C91.814 40.9189 93.8715 42.9763 95.9189 45.0238C95.9688 45.0737 95.9688 45.0737 95.9189 45.1237C95.1798 45.8627 94.4507 46.5918 93.7117 47.3309C93.6617 47.3808 93.6617 47.3808 93.6118 47.3309C92.3234 46.0425 91.045 44.7641 89.7566 43.4757C89.6967 43.4158 89.7067 43.4158 89.6567 43.4757C88.3783 44.7541 87.0899 46.0425 85.8115 47.3209C85.7516 47.3808 85.7616 47.3808 85.7017 47.3209C84.9726 46.5918 84.2335 45.8527 83.5044 45.1237C83.4445 45.0637 83.4445 45.0737 83.5044 45.0138C85.5519 42.9664 87.5993 40.9189 89.6467 38.8715C89.6667 38.8515 89.6867 38.8415 89.6967 38.8215C89.6967 38.8215 89.7067 38.8215 89.7166 38.8215Z"
        fill="black"
      />
      <path
        d="M99.8842 49.0087C99.8642 49.0187 99.8542 49.0387 99.8343 49.0486C99.0952 49.7777 98.3661 50.5168 97.627 51.2559C97.5771 51.3058 97.5871 51.3058 97.5271 51.2559C96.7881 50.5168 96.049 49.7777 95.3099 49.0387C95.26 48.9887 95.26 48.9987 95.3099 48.9488C96.049 48.2097 96.7881 47.4706 97.5271 46.7315C97.5771 46.6816 97.5671 46.6816 97.617 46.7315C98.3561 47.4706 99.0852 48.1997 99.8243 48.9388C99.8442 48.9588 99.8542 48.9687 99.8742 48.9787C99.8842 48.9987 99.8842 48.9987 99.8842 49.0087Z"
        fill="black"
      />
      <path
        d="M95.9589 52.924C95.9589 52.934 95.9589 52.934 95.9489 52.944C95.9389 52.954 95.9289 52.964 95.9189 52.974C93.8615 55.0314 91.8041 57.0888 89.7466 59.1462C89.6967 59.1962 89.7067 59.1962 89.6468 59.1462C87.5893 57.0888 85.5319 55.0314 83.4745 52.974C83.4245 52.924 83.4245 52.934 83.4745 52.8841C84.2135 52.145 84.9526 51.4059 85.6917 50.6668C85.7416 50.6169 85.7416 50.6169 85.7916 50.6668C87.08 51.9552 88.3584 53.2336 89.6468 54.522C89.7067 54.582 89.6867 54.582 89.7466 54.522C91.035 53.2336 92.3234 51.9552 93.6018 50.6668C93.6517 50.6169 93.6517 50.6169 93.7017 50.6668C94.4408 51.4059 95.1798 52.145 95.9189 52.8841C95.9389 52.8941 95.9489 52.9041 95.9589 52.924Z"
        fill="black"
      />
      <path
        d="M92.0337 48.9989C92.0337 49.0089 92.0337 49.0089 92.0238 49.0189C92.0138 49.0289 92.0038 49.0389 91.9938 49.0488C91.2447 49.7979 90.4957 50.547 89.7466 51.296C89.6967 51.346 89.7067 51.346 89.6567 51.296C88.9077 50.547 88.1586 49.7979 87.4095 49.0488C87.3596 48.9989 87.3596 49.0089 87.4095 48.959C88.1586 48.2099 88.9077 47.4608 89.6567 46.7118C89.7067 46.6618 89.6967 46.6618 89.7466 46.7118C90.4957 47.4608 91.2447 48.2099 91.9938 48.959C92.0138 48.9689 92.0238 48.9789 92.0337 48.9989Z"
        fill="black"
      />
      <path
        d="M84.1237 48.9987C84.1237 48.9987 84.1237 49.0087 84.1137 49.0087C84.1037 49.0187 84.0938 49.0287 84.0838 49.0387C83.3447 49.7777 82.6056 50.5168 81.8665 51.2559C81.8166 51.3058 81.8266 51.3058 81.7767 51.2559C81.0376 50.5168 80.2985 49.7777 79.5594 49.0387C79.5095 48.9887 79.5095 48.9987 79.5594 48.9488C80.2985 48.2097 81.0376 47.4706 81.7767 46.7315C81.8266 46.6816 81.8166 46.6816 81.8665 46.7315C82.6056 47.4706 83.3447 48.2097 84.0838 48.9488C84.0938 48.9588 84.1037 48.9687 84.1137 48.9787C84.1137 48.9887 84.1237 48.9887 84.1237 48.9987Z"
        fill="black"
      />
      <path
        d="M50.3959 79.5006C48.9278 79.5006 47.6793 78.4519 47.4297 76.9937L39.0401 27.7153C38.9103 26.9263 39.0901 26.1273 39.5495 25.4682C40.0089 24.809 40.7081 24.3795 41.5071 24.2497C43.145 23.98 44.7031 25.0786 44.9827 26.7166L53.3622 75.995C53.4921 76.784 53.3123 77.583 52.8529 78.2422C52.3835 78.9014 51.6943 79.3308 50.9053 79.4706C50.7355 79.4906 50.5657 79.5006 50.3959 79.5006Z"
        fill="black"
      />
      <path
        d="M64.4386 56.7791C59.6446 56.7791 55.1902 58.2173 51.4648 60.6742L52.4836 66.6367C55.6895 63.8502 59.8743 62.1523 64.4386 62.1523C74.496 62.1523 82.6758 70.3321 82.6758 80.3895C82.6758 90.447 74.496 98.6267 64.4386 98.6267C54.3812 98.6267 46.2014 90.447 46.2014 80.3895C46.2014 79.0512 46.3512 77.7428 46.6309 76.4844L45.0129 66.9863C42.3762 70.7915 40.8281 75.4158 40.8281 80.3895C40.8281 93.4033 51.4149 104 64.4386 104C77.4623 104 88.0491 93.4033 88.0491 80.3895C88.0491 67.3758 77.4623 56.7791 64.4386 56.7791Z"
        fill="black"
      />
      <path
        d="M63.1501 94.1624C64.9262 94.1624 66.366 92.7226 66.366 90.9464C66.366 89.1703 64.9262 87.7305 63.1501 87.7305C61.3739 87.7305 59.9341 89.1703 59.9341 90.9464C59.9341 92.7226 61.3739 94.1624 63.1501 94.1624Z"
        fill="black"
      />
      <path
        d="M73.1974 90.4171C74.9736 90.4171 76.4134 88.9772 76.4134 87.2011C76.4134 85.425 74.9736 83.9851 73.1974 83.9851C71.4213 83.9851 69.9814 85.425 69.9814 87.2011C69.9814 88.9772 71.4213 90.4171 73.1974 90.4171Z"
        fill="black"
      />
      <path
        d="M65.297 83.5257C67.0732 83.5257 68.513 82.0859 68.513 80.3097C68.513 78.5336 67.0732 77.0938 65.297 77.0938C63.5209 77.0938 62.0811 78.5336 62.0811 80.3097C62.0811 82.0859 63.5209 83.5257 65.297 83.5257Z"
        fill="black"
      />
      <path
        d="M55.3097 86.8116C57.0859 86.8116 58.5257 85.3718 58.5257 83.5956C58.5257 81.8195 57.0859 80.3796 55.3097 80.3796C53.5336 80.3796 52.0938 81.8195 52.0938 83.5956C52.0938 85.3718 53.5336 86.8116 55.3097 86.8116Z"
        fill="black"
      />
      <path
        d="M75.2448 80.2298C77.0209 80.2298 78.4608 78.79 78.4608 77.0138C78.4608 75.2377 77.0209 73.7979 75.2448 73.7979C73.4686 73.7979 72.0288 75.2377 72.0288 77.0138C72.0288 78.79 73.4686 80.2298 75.2448 80.2298Z"
        fill="black"
      />
      <path
        d="M67.4445 72.889C69.2206 72.889 70.6605 71.4491 70.6605 69.673C70.6605 67.8969 69.2206 66.457 67.4445 66.457C65.6684 66.457 64.2285 67.8969 64.2285 69.673C64.2285 71.4491 65.6684 72.889 67.4445 72.889Z"
        fill="black"
      />
      <path
        d="M57.3673 76.6243C59.1435 76.6243 60.5833 75.1845 60.5833 73.4084C60.5833 71.6322 59.1435 70.1924 57.3673 70.1924C55.5912 70.1924 54.1514 71.6322 54.1514 73.4084C54.1514 75.1845 55.5912 76.6243 57.3673 76.6243Z"
        fill="black"
      />
    </g>
    <defs>
      <linearGradient id="paint0_linear_706_842" x1="0" y1="0" x2="128" y2="140.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1CD8D2" />
        <stop offset="1" stopColor="#CCFF00" />
      </linearGradient>
      <clipPath id="clip0_706_842">
        <rect width="60.9238" height="80" fill="white" transform="translate(39 24)" />
      </clipPath>
    </defs>
  </svg>
)
