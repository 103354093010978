import { isAddress } from '@ethersproject/address';
import { useMemo } from 'react';
const alwaysTrue = () => true;
/**
 * Create a filter function to apply to a token for whether it matches a particular search query
 * @param search the search query to apply to the token
 */
export function createTokenFilterFunction(search) {
    const isValidAddress = isAddress(search);
    if (isValidAddress) {
        return (t) => search.toLowerCase() === t.address.toLowerCase();
    }
    const lowerSearchParts = search
        .toLowerCase()
        .split(/\s+/)
        .filter((s) => s.length > 0);
    if (lowerSearchParts.length === 0)
        return alwaysTrue;
    const matchesSearch = (s) => {
        const sParts = s
            .toLowerCase()
            .split(/\s+/)
            .filter((s) => s.length > 0);
        return lowerSearchParts.every((p) => p.length === 0 || sParts.some((sp) => sp.startsWith(p) || sp.endsWith(p)));
    };
    return ({ name, symbol }) => Boolean((symbol && matchesSearch(symbol)) || (name && matchesSearch(name)));
}
export function filterTokens(tokens, search) {
    return tokens.filter(createTokenFilterFunction(search));
}
export const balanceComparator = (balanceA, balanceB) => {
    if (balanceA && balanceB) {
        if (balanceA.asFraction.equalTo(balanceB.asFraction))
            return 0;
        return balanceA.asFraction.greaterThan(balanceB.asFraction) ? -1 : 1;
    }
    else if (balanceA && balanceA.greaterThan('0')) {
        return -1;
    }
    else if (balanceB && balanceB.greaterThan('0')) {
        return 1;
    }
    return 0;
};
export const tokenComparator = (balancesMap, pricesMap, fundSource) => {
    return (tokenA, tokenB) => {
        var _a, _b, _c, _d;
        const balanceA = (pricesMap === null || pricesMap === void 0 ? void 0 : pricesMap[tokenA.address])
            ? (_b = (_a = balancesMap === null || balancesMap === void 0 ? void 0 : balancesMap[tokenA.address]) === null || _a === void 0 ? void 0 : _a[fundSource]) === null || _b === void 0 ? void 0 : _b.multiply(pricesMap[tokenA.address])
            : undefined;
        const balanceB = (pricesMap === null || pricesMap === void 0 ? void 0 : pricesMap[tokenB.address])
            ? (_d = (_c = balancesMap === null || balancesMap === void 0 ? void 0 : balancesMap[tokenB.address]) === null || _c === void 0 ? void 0 : _c[fundSource]) === null || _d === void 0 ? void 0 : _d.multiply(pricesMap[tokenB.address])
            : undefined;
        const balanceComp = balanceComparator(balanceA, balanceB);
        if (balanceComp !== 0) {
            return balanceComp;
        }
        if (tokenA.symbol && tokenB.symbol) {
            // sort by symbol
            return tokenA.symbol.toLowerCase() < tokenB.symbol.toLowerCase() ? -1 : 1;
        }
        else {
            return tokenA.symbol ? -1 : tokenB.symbol ? -1 : 0;
        }
    };
};
export function useSortedTokensByQuery(tokens, searchQuery) {
    return useMemo(() => {
        if (!tokens) {
            return [];
        }
        if (searchQuery === '') {
            return tokens;
        }
        const symbolMatch = searchQuery
            .toLowerCase()
            .split(/\s+/)
            .filter((s) => s.length > 0);
        if (symbolMatch.length > 1) {
            return tokens;
        }
        const exactMatches = [];
        const symbolSubstrings = [];
        const rest = [];
        // sort tokens by exact match -> subtring on symbol match -> rest
        tokens.map((token) => {
            var _a, _b;
            if (((_a = token.symbol) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === symbolMatch[0]) {
                return exactMatches.push(token);
            }
            else if ((_b = token.symbol) === null || _b === void 0 ? void 0 : _b.toLowerCase().startsWith(searchQuery.toLowerCase().trim())) {
                return symbolSubstrings.push(token);
            }
            else {
                return rest.push(token);
            }
        });
        return [...exactMatches, ...symbolSubstrings, ...rest];
    }, [tokens, searchQuery]);
}
