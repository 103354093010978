// src/Big.ts
import Big from "big.js";
import toFormat from "toformat";
Big.strict = true;
var Big_default = toFormat(Big);

// src/constants/numbers.ts
import JSBI3 from "jsbi";

// src/Percent.ts
import { formatPercent } from "@sushiswap/format";
import JSBI2 from "jsbi";

// src/Fraction.ts
import JSBI from "jsbi";
import invariant from "tiny-invariant";

// src/Decimal.ts
import Decimal from "decimal.js-light";
import toFormat2 from "toformat";
var Decimal_default = toFormat2(Decimal);

// src/Rounding.ts
var Rounding = /* @__PURE__ */ ((Rounding2) => {
  Rounding2[Rounding2["ROUND_DOWN"] = 0] = "ROUND_DOWN";
  Rounding2[Rounding2["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
  Rounding2[Rounding2["ROUND_UP"] = 3] = "ROUND_UP";
  return Rounding2;
})(Rounding || {});
var Rounding_default = Rounding;

// src/Fraction.ts
var toSignificantRounding = {
  [Rounding_default.ROUND_DOWN]: Decimal_default.ROUND_DOWN,
  [Rounding_default.ROUND_HALF_UP]: Decimal_default.ROUND_HALF_UP,
  [Rounding_default.ROUND_UP]: Decimal_default.ROUND_UP
};
var toFixedRounding = {
  [Rounding_default.ROUND_DOWN]: Big_default.roundDown,
  [Rounding_default.ROUND_HALF_UP]: Big_default.roundHalfUp,
  [Rounding_default.ROUND_UP]: Big_default.roundUp
};
var Fraction = class {
  constructor(numerator, denominator = JSBI.BigInt(1)) {
    this.numerator = JSBI.BigInt(typeof numerator === "bigint" ? numerator.toString() : numerator);
    this.denominator = JSBI.BigInt(typeof denominator === "bigint" ? denominator.toString() : denominator);
  }
  static tryParseFraction(fractionish) {
    if (fractionish instanceof JSBI || typeof fractionish === "number" || typeof fractionish === "string" || typeof fractionish === "bigint")
      return new Fraction(fractionish);
    if ("numerator" in fractionish && "denominator" in fractionish)
      return fractionish;
    throw new Error("Could not parse fraction");
  }
  get quotient() {
    return JSBI.divide(this.numerator, this.denominator);
  }
  get remainder() {
    return new Fraction(JSBI.remainder(this.numerator, this.denominator), this.denominator);
  }
  invert() {
    return new Fraction(this.denominator, this.numerator);
  }
  add(other) {
    const otherParsed = Fraction.tryParseFraction(other);
    if (JSBI.equal(this.denominator, otherParsed.denominator)) {
      return new Fraction(JSBI.add(this.numerator, otherParsed.numerator), this.denominator);
    }
    return new Fraction(
      JSBI.add(
        JSBI.multiply(this.numerator, otherParsed.denominator),
        JSBI.multiply(otherParsed.numerator, this.denominator)
      ),
      JSBI.multiply(this.denominator, otherParsed.denominator)
    );
  }
  subtract(other) {
    const otherParsed = Fraction.tryParseFraction(other);
    if (JSBI.equal(this.denominator, otherParsed.denominator)) {
      return new Fraction(JSBI.subtract(this.numerator, otherParsed.numerator), this.denominator);
    }
    return new Fraction(
      JSBI.subtract(
        JSBI.multiply(this.numerator, otherParsed.denominator),
        JSBI.multiply(otherParsed.numerator, this.denominator)
      ),
      JSBI.multiply(this.denominator, otherParsed.denominator)
    );
  }
  lessThan(other) {
    const otherParsed = Fraction.tryParseFraction(other);
    return JSBI.lessThan(
      JSBI.multiply(this.numerator, otherParsed.denominator),
      JSBI.multiply(otherParsed.numerator, this.denominator)
    );
  }
  equalTo(other) {
    const otherParsed = Fraction.tryParseFraction(other);
    return JSBI.equal(
      JSBI.multiply(this.numerator, otherParsed.denominator),
      JSBI.multiply(otherParsed.numerator, this.denominator)
    );
  }
  greaterThan(other) {
    const otherParsed = Fraction.tryParseFraction(other);
    return JSBI.greaterThan(
      JSBI.multiply(this.numerator, otherParsed.denominator),
      JSBI.multiply(otherParsed.numerator, this.denominator)
    );
  }
  multiply(other) {
    const otherParsed = Fraction.tryParseFraction(other);
    return new Fraction(
      JSBI.multiply(this.numerator, otherParsed.numerator),
      JSBI.multiply(this.denominator, otherParsed.denominator)
    );
  }
  divide(other) {
    const otherParsed = Fraction.tryParseFraction(other);
    return new Fraction(
      JSBI.multiply(this.numerator, otherParsed.denominator),
      JSBI.multiply(this.denominator, otherParsed.numerator)
    );
  }
  toSignificant(significantDigits, format = { groupSeparator: "" }, rounding = Rounding_default.ROUND_HALF_UP) {
    invariant(Number.isInteger(significantDigits), `${significantDigits} is not an integer.`);
    invariant(significantDigits > 0, `${significantDigits} is not positive.`);
    Decimal_default.set({
      precision: significantDigits + 1,
      rounding: toSignificantRounding[rounding]
    });
    const quotient = new Decimal_default(this.numerator.toString()).div(this.denominator.toString()).toSignificantDigits(significantDigits);
    return quotient.toFormat(quotient.decimalPlaces(), format);
  }
  toFixed(decimalPlaces, format = { groupSeparator: "" }, rounding = Rounding_default.ROUND_HALF_UP) {
    invariant(Number.isInteger(decimalPlaces), `${decimalPlaces} is not an integer.`);
    invariant(decimalPlaces >= 0, `${decimalPlaces} is negative.`);
    Big_default.DP = decimalPlaces;
    Big_default.RM = toFixedRounding[rounding];
    return new Big_default(this.numerator.toString()).div(this.denominator.toString()).toFormat(decimalPlaces, format);
  }
  get asFraction() {
    return new Fraction(this.numerator, this.denominator);
  }
};
var Fraction_default = Fraction;

// src/Percent.ts
var ONE_HUNDRED = new Fraction_default(JSBI2.BigInt(100));
function toPercent(fraction) {
  return new Percent(fraction.numerator, fraction.denominator);
}
var Percent = class extends Fraction_default {
  constructor() {
    super(...arguments);
    this.isPercent = true;
  }
  add(other) {
    return toPercent(super.add(other));
  }
  subtract(other) {
    return toPercent(super.subtract(other));
  }
  multiply(other) {
    return toPercent(super.multiply(other));
  }
  divide(other) {
    return toPercent(super.divide(other));
  }
  toSignificant(significantDigits = 5, format, rounding) {
    return super.multiply(ONE_HUNDRED).toSignificant(significantDigits, format, rounding);
  }
  toFixed(decimalPlaces = 2, format, rounding) {
    return super.multiply(ONE_HUNDRED).toFixed(decimalPlaces, format, rounding);
  }
  toPercentageString(decimalPlaces = 2) {
    return formatPercent(super.toFixed(decimalPlaces + 2));
  }
};
var Percent_default = Percent;

// src/constants/numbers.ts
var ZERO = JSBI3.BigInt(0);
var ONE = JSBI3.BigInt(1);
var TWO = JSBI3.BigInt(2);
var THREE = JSBI3.BigInt(3);
var FIVE = JSBI3.BigInt(5);
var TEN = JSBI3.BigInt(10);
var _100 = JSBI3.BigInt(100);
var _997 = JSBI3.BigInt(997);
var _1000 = JSBI3.BigInt(1e3);
var _9994 = JSBI3.BigInt(9994);
var _9995 = JSBI3.BigInt(9995);
var _10000 = JSBI3.BigInt(1e4);
var _1e18 = JSBI3.BigInt(1e18);
var _1e12 = JSBI3.BigInt(1e12);
var _1e9 = JSBI3.BigInt(1e9);
var _1e6 = JSBI3.BigInt(1e6);
var MAX_SAFE_INTEGER = JSBI3.BigInt(Number.MAX_SAFE_INTEGER);
var MAX_UINT256 = JSBI3.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
var MAX_UINT128 = JSBI3.subtract(JSBI3.exponentiate(JSBI3.BigInt("2"), JSBI3.BigInt(128)), JSBI3.BigInt(1));
var ZERO_PERCENT = new Percent_default(0);

// src/functions/difference.ts
import JSBI4 from "jsbi";
function difference(a, b) {
  if (JSBI4.greaterThan(a, b)) {
    return JSBI4.subtract(a, b);
  }
  return JSBI4.subtract(b, a);
}

// src/functions/maximum.ts
import JSBI5 from "jsbi";
function maximum(...values) {
  let highest = values[0];
  for (let i = 1; i < values.length; i++) {
    const value = values[i];
    if (JSBI5.GT(value, highest)) {
      highest = value;
    }
  }
  return highest;
}

// src/functions/minimum.ts
import JSBI6 from "jsbi";
function minimum(...values) {
  let lowest = values[0];
  for (let i = 1; i < values.length; i++) {
    const value = values[i];
    if (JSBI6.LT(value, lowest)) {
      lowest = value;
    }
  }
  return lowest;
}

// src/functions/sqrt.ts
import JSBI7 from "jsbi";
import invariant2 from "tiny-invariant";
function sqrt(value) {
  invariant2(JSBI7.greaterThanOrEqual(value, ZERO), "NEGATIVE");
  if (JSBI7.lessThan(value, MAX_SAFE_INTEGER)) {
    return JSBI7.BigInt(Math.floor(Math.sqrt(JSBI7.toNumber(value))));
  }
  let z;
  let x;
  z = value;
  x = JSBI7.add(JSBI7.divide(value, TWO), ONE);
  while (JSBI7.lessThan(x, z)) {
    z = x;
    x = JSBI7.divide(JSBI7.add(JSBI7.divide(value, x), x), TWO);
  }
  return z;
}

// src/index.ts
import { default as default2 } from "jsbi";
export {
  Big_default as Big,
  Decimal_default as Decimal,
  FIVE,
  Fraction_default as Fraction,
  default2 as JSBI,
  MAX_SAFE_INTEGER,
  MAX_UINT128,
  MAX_UINT256,
  ONE,
  Percent_default as Percent,
  Rounding_default as Rounding,
  TEN,
  THREE,
  TWO,
  ZERO,
  ZERO_PERCENT,
  _100,
  _1000,
  _10000,
  _1e12,
  _1e18,
  _1e6,
  _1e9,
  _997,
  _9994,
  _9995,
  difference,
  maximum,
  minimum,
  sqrt
};
