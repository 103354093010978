import * as React from 'react'

export const AvalancheCircle = (props: React.ComponentProps<'svg'>) => (
  <svg viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={128} height={128} rx={64} fill="#E84142" />
    <path d="M103.473 21.957H24.442v71.83h79.031v-71.83Z" fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M127.957 64c0 35.3-28.634 63.915-63.957 63.915S.042 99.3.042 64 28.677.085 64 .085c35.323 0 63.957 28.616 63.957 63.915Zm-82.08 25.435H33.463c-2.608 0-3.897 0-4.682-.502a3.152 3.152 0 0 1-1.43-2.466c-.047-.926.597-2.056 1.885-4.317l30.648-53.985c1.304-2.293 1.964-3.439 2.797-3.863a3.164 3.164 0 0 1 2.86 0c.832.424 1.492 1.57 2.796 3.863l6.3 10.99.033.057c1.408 2.46 2.122 3.706 2.434 5.015a9.318 9.318 0 0 1 0 4.365c-.314 1.32-1.021 2.575-2.45 5.072l-16.1 28.438-.041.073c-1.418 2.48-2.136 3.737-3.132 4.685a9.37 9.37 0 0 1-3.818 2.214c-1.304.361-2.765.361-5.688.361Zm31.345 0h17.785c2.624 0 3.944 0 4.73-.517a3.143 3.143 0 0 0 1.43-2.481c.045-.897-.585-1.983-1.82-4.113l-.129-.221-8.909-15.23-.101-.172c-1.252-2.116-1.884-3.184-2.695-3.597a3.13 3.13 0 0 0-2.844 0c-.817.424-1.477 1.539-2.781 3.784l-8.877 15.23-.03.053c-1.3 2.242-1.95 3.362-1.903 4.281a3.18 3.18 0 0 0 1.43 2.48c.77.503 2.09.503 4.714.503Z"
      fill="#E84142"
    />
  </svg>
)
